import * as React from 'react';
import initialMetricsPublisher, { ArgoMetricsConfig } from '../../utils/metrics/metrics';
import katalLogger from '../../utils/logger/logger';
import * as KatalMetrics from '@katal/metrics';
import { Level } from '@katal/logger';

// Add this email mapping
const SUPPORT_EMAIL_MAP: Record<string, string> = {
    'approval': 'aws-scm-budgeting@amazon.com',
    'budgeting': 'aws-scm-budgeting@amazon.com',
    'costing': 'aws-ics@amazon.com',
    'dc-rack-provisioning': 'dc-rack-provisioning@amazon.com',
    'fulfillment-insights': 'aws-scm-pi-devs@amazon.com',
    'assets-inventory': 'cyberops@amazon.com',
    'inventory-app': 'aws-scm-inventory-visibility@amazon.com',
    'file-depot-app': 'aws-scm-offers@amazon.com',
    'offers': 'aws-scm-offers@amazon.com',
    'one-supply': 'aws-ics@amazon.com',
    'planning-configurations': 'aws-scm-sct@amazon.com',
    'ordering': 'aws-scm-requisition@amazon.com',
    'smart': 'aws-scm-offers@amazon.com',
    'sales-order-management': 'aws-demand-management@amazon.com',
    'edi-self-service': 'aws-scm-forecast-edi@amazon.com',
    'shipments': 'aws-scm-shipment@amazon.com',
    'supplier-forecasts': 'aws-scm-inventory-visibility@amazon.com',
    'supplier-procurement': 'aws-scm-procurement-execution@amazon.com',
    'townsend': 'townsend-apps@amazon.com',
    'asset-transfer': 'scos-jupiter@amazon.com',
    'user-management': 'aws-scm-auth@amazon.com',
    'vendor-master': 'aws-scm-vendor-master@amazon.com',
    'watch-tower': 'aws-demand-management@amazon.com',
    'default': 'aws-scm-ux@amazon.com'
};

const getAppNameFromPath = (): string => {
  const path = window.location.pathname;
  // Split by '/' and get the first non-empty segment
  const segments = path.split('/').filter(Boolean);
  const appName = segments[0] || 'default';
  return appName.toLowerCase();
};

interface ErrorBoundaryProps {
  appName: string;
  region: string;
  frameworkStage: string;
  cell: string;
  katalLoggerUrl: string;
  argoHadesUrl: string;
  browserName: string;
  deviceType: string;
  deviceOS: string;
  locale: string;
  applicationVisitId: string;
  argoSessionId: string;
}

interface ErrorBoundaryState {
  actionMetricsPublisher: KatalMetrics.Publisher;
  hasError?: boolean;
}

class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
  > {
  constructor(props: ErrorBoundaryProps) {
    super(props);

    const errorBoundaryMetricsConfig: ArgoMetricsConfig = {
      ...this.props
    }

    this.state = {
      actionMetricsPublisher: initialMetricsPublisher(errorBoundaryMetricsConfig).newChildActionPublisherForMethod('FatalError')
    };
  }

  static getDerivedStateFromError(error: Error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: Error, info: object) {
    const logToConsole = this.props.appName === 'argo-test' ? true : false;
    this.emitErrorMetric();
    this.emitErrorLog(error, info, logToConsole, this.props.katalLoggerUrl)
  }

  emitErrorMetric = () => {
    this.state.actionMetricsPublisher.publishCounterMonitor(
      'FatalError',
      1
    );
  };

  emitErrorLog = (error: Error, info: object, logToConsole: boolean, katalLoggerUrl?: string) => {
    katalLogger(
      Level.WARN,
      {
        appName: this.props.appName,
      },
      logToConsole,
      katalLoggerUrl
    ).error('FatalError', error, info);
  }

  getSupportEmail = () => {
    const urlAppName = getAppNameFromPath();
    return SUPPORT_EMAIL_MAP[urlAppName] || SUPPORT_EMAIL_MAP.default;
  }

  render() {
      if (this.state.hasError) {
          const supportEmail = this.getSupportEmail();
          const urlAppName = getAppNameFromPath();
          return (
              <div style={{
                  position: 'fixed',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  backgroundColor: '#ffffff',
                  padding: '3rem',
                  borderRadius: '8px',
                  boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
                  maxWidth: '600px',
                  width: '90%',
                  fontFamily: 'Amazon Ember, Arial, sans-serif',
                  zIndex: 1000
              }}>
                  <h2 style={{
                      fontSize: '2rem',
                      color: '#16191f',
                      marginTop: 0,
                      marginBottom: '2rem',
                      borderBottom: '1px solid #eaeded',
                      paddingBottom: '1rem'
                  }}>
                      Something went wrong with the Infra Portal
                  </h2>

                  <p style={{
                      fontSize: '1.25rem',
                      color: '#16191f',
                      marginBottom: '2rem',
                      lineHeight: '1.5'
                  }}>
                      Application: <strong>{urlAppName}</strong>
                  </p>

                  <p style={{
                      fontSize: '1.25rem',
                      color: '#16191f',
                      marginBottom: '1rem'
                  }}>
                      Please contact support:
                  </p>

                  <a
                      href={`mailto:${supportEmail}`}
                      style={{
                          display: 'inline-block',
                          color: '#0073bb',
                          textDecoration: 'none',
                          fontSize: '1.25rem',
                          fontWeight: '500'
                      }}
                  >
                      {supportEmail}
                  </a>
              </div>
          );
      }

    // This will render the Cognito or Dev app component.
    return <div>{this.props.children}</div>;
  }
}

export default ErrorBoundary;
